import React from 'react';
import {
    AboutSection,
    HeroSection,
    InterestsSection,
    Page,
    Seo,
} from 'gatsby-theme-portfolio-minimal';

export default function IndexPage() {
    return (
        <>
            <Seo title="IT Beratung Kraft" />
            <Page useSplashScreenAnimation>
                <HeroSection sectionId="hero" />
                <AboutSection sectionId="about" heading="About Us" />
                <InterestsSection sectionId="details" heading="Specialties" />
            </Page>
        </>
    );
}